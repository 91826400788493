<template>
  <div :class="$style.catalog">
    <div :class="$style.wrapper">
      <div :class="$style.filterWrapper">
        <div :class="$style.header">
          <span :class="$style.assortmentName">Главный Каталог</span>
          <el-button
            size="small"
            type="primary"
            :disabled="!catalog.selectedProducts.length"
            @click="addProducts"
          >
            Добавить
          </el-button>
        </div>
        <div :class="$style.filters">
          <div :class="$style.search">
            <Autocomplete
              :search="catalog.query.search"
              placeholder="Поиск"
              :valueNames="['name']"
              @querySearch="querySearch($event, 'catalog')"
              @selectItem="handleSelectProduct"
              @handleFindItems="setCatalog('catalog')"
              @input="catalog.query.search = $event"
            />
            <el-button
              :class="$style.searchButton"
              type="primary"
              @click="setCatalog('catalog')"
            >
              Показать
            </el-button>
          </div>
          <div :class="$style.filtersButtonWrapper">
            <el-button
              :class="$style.filtersButton"
              type="primary"
              @click="handleClickFilters('catalog')"
            >
              <Icon name="filters" :class="$style.icon" /> <span>Фильтры</span>
            </el-button>
            <DropdownFilters
              v-model="catalog.filters"
              v-if="catalog.isFiltersOpen"
              title="Фильтры поиска товаров"
              :options="filtersOptions"
              @applyFilters="applyFilters('catalog')"
              @resetFilters="resetFilters('catalog')"
              @close="catalog.isFiltersOpen = false"
              :isOpen="catalog.isFiltersOpen"
            />
          </div>
        </div>
      </div>
      <el-table
        ref="catalogTable"
        :data="catalog.products"
        @selection-change="handleSelectionChange($event, 'catalog')"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          property="vendorCode"
          label="Артикул"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column property="name" label="Название" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="Изображение" width="120"
          ><template slot-scope="scope">
            <Gallery
              :images="setGalleryImages(scope.row?.images)"
              :image="setupImagesListData(scope.row?.images)?.original"
            /> </template
        ></el-table-column>
        <el-table-column width="100">
          <template slot-scope="scope">
            <ActionButtons
              :add-trash="false"
              :view-link="
                getRoute({
                  route: $options.ADDWINE_ROUTES.CATALOG.PRODUCT,
                  params: { id: scope.row?.slug },
                  site: $options.PROJECTS.ADDWINE,
                })
              "
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :class="$style.pagination"
        layout="prev, pager, next"
        :page-size="catalog.query.limit"
        :total="catalog.total"
        :current-page.sync="catalog.query.page"
        @current-change="setCatalog('catalog')"
        background
      >
      </el-pagination>
    </div>
    <div :class="$style.wrapper">
      <div :class="$style.filterWrapper">
        <div :class="$style.header">
          <span :class="$style.assortmentName">{{ assortment.name }}</span>
          <el-button
            size="small"
            type="primary"
            :disabled="!assortmentCatalog.selectedProducts.length"
            @click="removeProducts"
          >
            Удалить
          </el-button>
        </div>
        <div :class="$style.filters">
          <div :class="$style.search">
            <Autocomplete
              :search="assortmentCatalog.query.search"
              placeholder="Поиск"
              :valueNames="['name']"
              @querySearch="querySearch($event, 'assortmentCatalog')"
              @selectItem="handleSelectProduct"
              @handleFindItems="setCatalog('assortmentCatalog')"
              @input="assortmentCatalog.query.search = $event"
            />
            <el-button
              :class="$style.searchButton"
              type="primary"
              @click="setCatalog('assortmentCatalog')"
            >
              Показать
            </el-button>
          </div>
          <div :class="$style.filtersButtonWrapper">
            <el-button
              :class="$style.filtersButton"
              type="primary"
              @click="handleClickFilters('assortmentCatalog')"
            >
              <Icon name="filters" :class="$style.icon" /> <span>Фильтры</span>
            </el-button>
            <DropdownFilters
              v-model="assortmentCatalog.filters"
              v-if="assortmentCatalog.isFiltersOpen"
              title="Фильтры поиска товаров"
              :options="filtersOptions"
              @applyFilters="applyFilters('assortmentCatalog')"
              @resetFilters="resetFilters('assortmentCatalog')"
              @close="assortmentCatalog.isFiltersOpen = false"
              :isOpen="assortmentCatalog.isFiltersOpen"
            />
          </div>
        </div>
      </div>

      <el-table
        ref="catalogTable"
        :data="assortmentCatalog.products"
        @selection-change="handleSelectionChange($event, 'assortmentCatalog')"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          property="vendorCode"
          label="Артикул"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column property="name" label="Название" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="Изображение" width="120"
          ><template slot-scope="scope">
            <Gallery
              :images="setGalleryImages(scope.row?.images)"
              :image="setupImagesListData(scope.row?.images)?.original"
            /> </template
        ></el-table-column>
        <el-table-column width="100">
          <template slot-scope="scope">
            <ActionButtons
              :add-trash="false"
              :view-link="
                getRoute({
                  route: $options.ADDWINE_ROUTES.CATALOG.PRODUCT,
                  params: { id: scope.row?.slug },
                  site: $options.PROJECTS.ADDWINE,
                })
              "
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :class="$style.pagination"
        layout="prev, pager, next"
        :page-size="assortmentCatalog.query.limit"
        :total="assortmentCatalog.total"
        :current-page.sync="assortmentCatalog.query.page"
        @current-change="setCatalog('assortmentCatalog')"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getRoute, ADDWINE_ROUTES } from '@/constants/routing'
import { PROJECTS } from '@/constants/projects'

import delivery from '@/delivery'
import images from '@/mixins/images'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'
import DropdownFilters from '@/components/atoms/DropdownFilters.vue'
import Icon from '@/components/atoms/Icon.vue'
import Gallery from '@/components/atoms/Gallery.vue'

export default {
  components: { Autocomplete, DropdownFilters, Icon, ActionButtons, Gallery },
  ADDWINE_ROUTES,
  PROJECTS,
  mixins: [images],
  mounted() {
    this.setFiltersOptions()
  },
  computed: {
    ...mapState({
      filtersOptions: (state) => state.filtersOptions,
    }),
  },
  data() {
    return {
      assortment: {},
      catalog: {
        products: [],
        selectedProducts: [],
        total: 0,
        query: {
          page: 1,
          limit: 20,
          search: '',
          minPrice: 7605,
          maxPrice: 7605,
        },
        isFiltersOpen: false,
        filters: {
          brands: [],
          assortments: [],
          categories: [],
          isActive: false,
          isAvailable: false,
          havePresents: false,
        },
      },
      assortmentCatalog: {
        products: [],
        selectedProducts: [],
        total: 0,
        query: {
          page: 1,
          limit: 20,
          search: '',
        },
        isFiltersOpen: false,
        filters: {
          brands: [],
          assortments: [],
          categories: [],
          isActive: false,
          isAvailable: false,
          havePresents: false,
        },
      },
    }
  },
  async created() {
    const success = await this.getAssortment()
    if (!success) {
      return
    }

    this.catalog.query.excludedAssortments = [this.assortment.slug]
    this.assortmentCatalog.filters.assortments = [this.assortment.slug]

    await this.refreshAllCatalogs()
  },
  methods: {
    setFiltersOptions() {
      this.$store.dispatch('getFiltersOptions')
    },
    async addProducts() {
      let isConfirmAddAll
      if (
        this.catalog.products.length === this.catalog.selectedProducts.length
      ) {
        isConfirmAddAll = confirm(
          'Добавить все продукты, соответствующие фильтрам?',
        )
      }
      if (isConfirmAddAll) {
        const resp =
          await delivery.ProductsCore.ProductAssortmentsActions.addByFilters(
            this.$route.params.id,
            this.catalog.query,
            this.catalog.filters,
          )
        if (resp.error) {
          alert('Не удалось добавить продукт в каталог')
          return
        }
        await this.refreshAllCatalogs()
        return
      }
      const productIds = {
        ids: this.catalog.selectedProducts.map((item) => item.id),
      }
      const { error } = await delivery.ProductsCore.AssortmentsActions.update(
        this.$route.params.id,
        { ...this.assortment, productIds },
      )
      if (error) {
        alert('Не удалось добавить продукт в каталог')
        return
      }

      await this.refreshAllCatalogs()
    },
    async removeProducts() {
      let isConfirmRemoveAll

      if (
        this.assortmentCatalog.products.length ===
        this.assortmentCatalog.selectedProducts.length
      ) {
        isConfirmRemoveAll = confirm(
          'Удалить все продукты, соответствующие фильтрам?',
        )
      }
      if (isConfirmRemoveAll) {
        const resp =
          await delivery.ProductsCore.ProductAssortmentsActions.deleteByFilters(
            this.$route.params.id,
            this.assortmentCatalog.query,
          )
        if (resp.error) {
          alert('Не удалось удалить продукты из каталога')
          return
        }
        await this.refreshAllCatalogs()
        return
      }

      const resp = await delivery.ProductsCore.ProductAssortmentsActions.delete(
        {
          assortmentId: this.$route.params.id,
          productIds: this.assortmentCatalog.selectedProducts.map(
            (item) => item.id,
          ),
        },
      )
      if (resp.error) {
        alert('Не удалось удалить продукты из каталога')
        return
      }

      await this.refreshAllCatalogs()
    },

    handleSelectionChange(val, name) {
      this[name].selectedProducts = val
    },

    async getAssortment() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ProductsCore.AssortmentsActions.getById(
          this.$route.params.id,
        )
      if (error) {
        this.$message({
          message: error,
          type: 'error',
        })
        return false
      }

      this.assortment = value

      loading.close()
      return true
    },

    async getCatalog(name) {
      const { query: contextQuery, filters } = this[name]
      const query = { ...contextQuery, ...filters }

      const { value, error } =
        await delivery.ProductsCore.CatalogsActions.getCatalog(query)

      if (error) return

      return { products: value.products.data, total: value.products.meta.count }
    },

    async setCatalog(name) {
      const response = await this.getCatalog(name)
      if (!response) return

      this[name].products = response.products
      this[name].total = response.total
    },

    async refreshAllCatalogs() {
      const loading = this.$loading({
        lock: true,
      })
      await this.setCatalog('catalog')
      await this.setCatalog('assortmentCatalog')
      loading.close()
    },
    handleSelectProduct(selectedBrand) {
      this.$router.push('/addwine/catalog/products/' + selectedBrand.id)
    },

    async querySearch({ setSearchItems }, name) {
      const { value, error } =
        await delivery.ProductsCore.CatalogsActions.getCatalog(this[name].query)

      if (error) return

      setSearchItems(value.products.data)
    },
    handleClickFilters(contextName) {
      this[contextName].isFiltersOpen = true
    },
    async applyFilters(contextName) {
      await this.setCatalog(contextName)
      this[contextName].isFiltersOpen = false
    },
    resetFilters(contextName) {
      this[contextName].filters = {
        brands: [],
        categories: [],
        assortments: [],
        isAvailable: false,
        havePresents: false,
        isActive: false,
      }
    },
    getRoute({ route, params, site }) {
      return getRoute({ route, params, site })
    },
  },
}
</script>

<style lang="scss" module>
.catalog {
  display: flex;
  padding: 0 1rem;
  .wrapper {
    margin-right: 1px;
    width: 100%;
    max-width: 50vw;
    .filterWrapper {
      display: flex;
      flex-direction: column;
      .filters {
        display: flex;
        align-items: center;
        .search {
          margin-right: 1rem;
          display: flex;
          align-items: center;
          input {
            border-radius: 0;
          }

          .searchButton {
            margin: 0;
            border-radius: 0;
          }
        }

        .filtersButtonWrapper {
          position: relative;

          .filtersButton {
            margin-right: 0;
          }
        }
      }
      @include stickyWrapper;
      .header {
        display: flex;
        align-items: center;
        .assortmentName {
          margin: 0 auto 0 0.5rem;
          font-weight: bold;
        }
      }
    }
    .pagination {
      @include pagination;
    }
  }
}
</style>
